import "./src/normalize.css";
import "./src/style.css";
// Highlighting for code blocks
import "./src/prism-coldark-cold.css";
import "prismjs/plugins/command-line/prism-command-line.css";
import "./src/styles/global.css";

import React from "react";
import { ThemeProvider } from "./src/context/theme_context";


export const onClientEntry = () => {
  const savedTheme = localStorage.getItem('theme');
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

  if (savedTheme === 'dark' || (!savedTheme && prefersDark)) {
    document.documentElement.classList.add('dark');
  }
};

export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <ThemeProvider>{element}</ThemeProvider>
);
